(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "Merge branch 'dev' into 'master'\n\nDev\n\nSee merge request lms-mob-edu/lms-legacy/legacy-front!163",
	"commit": "ac42c94110222c225d815384dc79acd57ab21aca",
	"branch": "master",
	"date": "2023-10-10T15:53:07.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
        .constant('innopolis', undefined)
        .constant('innopolis_stage', undefined)
        .constant('edu4', undefined)
        .constant('bit', undefined)
;
})();
